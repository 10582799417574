<template>
  <div class="database">
    <div class="searchBox">
      <el-input
        :placeholder="i18nData.placehold"
        v-model="search"
        class="input-search"
        @keyup.enter.native="toSearch"
      >
        <el-button
          class="button-search"
          slot="append"
          icon="el-icon-search"
          @click="toSearch"
        ></el-button>
      </el-input>
    </div>
    <div class="menuBox">
      <el-menu
        :default-active="databaseBar"
        class="el-menu-demo elmenu"
        mode="horizontal"
        @select="handleSelect"
        background-color="#0B6FBC"
        text-color="#dddddd"
        active-text-color="#fff"
      >
        <el-menu-item
          class="elmenuitem"
          v-for="item in tabList"
          :key="item.id"
          :index="String(item.id)"
        >
          <!-- {{locale == 'en-US'?item.diyname:item.title }} -->
          {{ item.diyname }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>{{ i18nData.toText }}:</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: location ? '/database' : '/blogs' }">
        {{location ? i18nData.to[0] : i18nData.to[1]}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <!-- {{
            archivesInfo.channel
              ? locale == "en-US"
                ? archivesInfo.channel.diyname
                : archivesInfo.channel.name
              : ""
          }} -->
          {{archivesInfo.channel?archivesInfo.channel.diyname:""}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ archivesInfo.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detailBox" v-loading="loading">
      <div class="detailShow" :class="unfoldState ? 'unfoldBox' : 'foldBox'">
        <div class="title">{{ archivesInfo.title }}</div>
        <div class="content" v-html="archivesInfo.content">
          <!-- <span>
            Beautiful Soup 3 only works on Python 2.x, but Beautiful Soup 4 also
            works on Python 3.x. Beautiful Soup 4 is faster, has more features,
            and works with third-party parsers like lxml and html5lib. You
            should use Beautiful Soup 4 for all new projects, and you should
            port any existing projects to Beautiful Soup 4.
          </span>
          <img src="./../../public/img/detail/detail_img1.png" alt="" />
          <span>
            Beautiful Soup 3 only works on Python 2.x, but Beautiful Soup 4 also
            works on Python 3.x. Beautiful Soup 4 is faster, has more features,
            and works with third-party parsers like lxml and html5lib. You
            should use Beautiful Soup 4 for all new projects, and you should
            port any existing projects to Beautiful Soup 4.
          </span> -->
        </div>
      </div>
      <div class="otherBox" v-if="unfoldState">
        <div class="data">{{ archivesInfo.create_date }}</div>
        <div class="other">
          <div>
            <i class="el-icon-chat-dot-square"></i
            ><span>{{ archivesInfo.comments }}</span>
          </div>
          <div>
            <i class="el-icon-view"></i><span>{{ archivesInfo.views }}</span>
          </div>

          <template v-if="collectStatus">
            <div class="chooseColor collection" @click="delCollection('del')">
              <i class="el-icon-star-on"></i>
              <span>Collected</span>
            </div>
          </template>
          <template v-else>
            <div
              class="collection"
              @click="LoggedIn ? addCollection() : toLogin()"
            >
              <i class="el-icon-star-off"></i>
              <span>Collect</span>
            </div>
          </template>
        </div>
      </div>
      <div class="unfold" v-if="!unfoldState" @click="toUnfold">
        <span><i class="el-icon-d-arrow-right"></i></span>
        <span>{{ i18nData.readmore }}</span>
      </div>
      <el-divider></el-divider>
      <div class="comment">
        <div v-if="!(commentList.length > 0)">
          {{ i18nData.nocomment }}
        </div>
        <div class="textareaBox">
          <i class="el-icon-edit-outline"></i>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :placeholder="i18nData.commenttip"
            v-model="content"
          >
          </el-input>
        </div>
        <div class="btn">
          <template v-if="LoggedIn">
            <div class="btnL" v-if="archivesInfo.price > 0">
              <el-button
                type="primary"
                size="small"
                plain
                @click="showModelFn"
                >{{ i18nData.applybtn }}</el-button
              >
            </div>
            <div class="btnR">
              <el-button type="primary" size="small" plain @click="submit">{{
                i18nData.comment
              }}</el-button>
            </div>
          </template>
          <template v-else>
            <div class="btnR">
              <div>{{ i18nData.buttontip }}</div>
              <el-button type="primary" size="small" plain @click="toLogin">{{
                i18nData.button
              }}</el-button>
            </div>
          </template>
        </div>
      </div>
      <div class="commentBox" v-if="commentList.length > 0">
        <div class="title">{{ i18nData.commentlist }}</div>
        <div class="commentList">
          <!-- <div class="commentItem">
            <div class="avatar">
              <img src="./../../public/img/detail/avatar_default.png" alt="" />
            </div>
            <div class="content">
              <span class="name">Mr Wu</span>
              <span class="comment"
                >Science is really great, and the biological world is
                amazing.</span
              >
            </div>
          </div> -->
          <div
            class="commentItem"
            v-for="(item, index) in commentList"
            :key="index"
          >
            <div class="avatar">
              <img :src="item.user && item.user.avatar" alt="" />
            </div>
            <div class="content">
              <div class="nameDate">
                <span class="name">{{ item.user && item.user.nickname }}</span>
                <span>{{ item.create_date }}</span>
              </div>
              <span class="comment" v-html="item.content"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="applyModel" v-show="showModel">
      <el-card class="box-card applyBox" :body-style="{ padding: '20px' }">
        <div class="close">
          <i class="el-icon-close" @click="showModelFn(1)"></i>
        </div>
        <div class="content">
          {{ i18nData.npopup.content }}
        </div>
        <div class="btnBox">
          <el-button type="primary" size="small" plain @click="showPhone">{{
            i18nData.npopup.button1
          }}</el-button>
          <el-button type="primary" size="small" plain @click="toApply">{{
            i18nData.npopup.button2
          }}</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { toTop, search } from "./../common/top.mixin.js";

export default {
  name: "detail",
  mixins: [toTop, search],
  components: {},
  data() {
    return {
      id: 0,
      loading: true,
      // 选中分类
      databaseBar: "",
      archivesInfo: {},
      channelInfo: {},
      commentList: [],
      imagesList: [],
      downloadurl: {},
      isRender: false,
      activeIndex2: "1",
      // 评论
      content: "",
      pid: 0,
      unfoldState: false,
      // 收藏状态
      collectStatus: false,
      // 是否登录
      LoggedIn: false,
      // 弹窗
      showModel: false,
      tabList: [
        {
          id: 0,
          diyname: "All",
          title: "全部"
        },
        {
          id: 10,
          diyname: "PAHs",
          title: "多环芳烃"
        },
        {
          id: 9,
          diyname: "EDCs",
          title: "内分泌干扰"
        },
        {
          id: 8,
          diyname: "PPCPs",
          title: "个人护理"
        },
        {
          id: 26,
          diyname: "DBPs",
          title: "消毒副产物"
        },
        {
          id: 25,
          diyname: "PFAS",
          title: "烷基类物质"
        },
        {
          id: 27,
          diyname: "OTHERs",
          title: "其他"
        }
      ]
    };
  },
  computed: {
    isShow() {
      return this.archivesInfo.images != "";
    },
    // 文章当前位置
    location() {
      console.log(this.archivesInfo.channel);
      let flag = false;
      if (this.archivesInfo.channel) {
        let tabList = this.tabList;
        flag = tabList.some(item => {
          return item.id == this.archivesInfo.channel.id;
        });
      }
      console.log(flag);
      return flag;
    }
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.id = this.$route.query.id;
    this.getCategory();
    this.getArchivesDetail();
    this.backTop();
    console.log(this.$route.query.id);
  },
  mounted() {
    if (this.$store.state.vuex_token) {
      this.LoggedIn = true;
      this.isCollection();
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.databaseBar = key;
      this.$router.push({
        path: "/database",
        query: {
          databaseBar: this.databaseBar
        }
      });
    },
    toUnfold() {
      this.unfoldState = true;
    },
    toLogin() {
      this.$message({
        message: "前往登录页面",
        type: "success"
      });
      setTimeout(() => {
        this.$router.push({ path: "/login" });
      }, 1200);
    },
    toApply() {
      this.$router.push({ path: "/my/mySubmit" });
    },
    async getArchivesDetail() {
      let res = await this.$api.https.getArchivesDetail({
        id: this.id
      });
      if (!res.code) {
        return;
      }
      this.loading = false;
      this.archivesInfo = res.data.archivesInfo || {};
      this.channelInfo = res.data.channelInfo || [];
      this.commentList = res.data.commentList || [];
      this.downloadurl = res.data.archivesInfo.downloadurl || {};
      this.imagesList = res.data.archivesInfo.images.split(",");
      this.isRender = true;
      this.$store.state.vuex__token__ = res.data.__token__;
    },
    // 获取分类
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          channel: -1,
          model: 2,
          menu_index: 1
        };
        let res = await this.$api.https.getCategory(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          this.tabList = res.data;
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 判断是否收藏isCollection
    async isCollection() {
      let parms = {
        aid: this.id,
        type: "archives"
      };
      let res = await this.$api.https.isCollection(parms);
      if (res.code) {
        this.collectStatus = true;
      } else {
        this.collectStatus = false;
      }
    },
    // 收藏
    async addCollection() {
      let parms = {
        aid: this.id,
        type: "archives"
      };
      let res = await this.$api.https.addCollection(parms);
      if (res.code) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.collectStatus = true;
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
      if (res.msg == "请勿重复收藏") {
        this.collectStatus = true;
      }
    },
    // 取消收藏
    async delCollection(flag) {
      try {
        let params = {
          aid: this.id,
          type: "archives"
        };
        let res = await this.$api.https.delsCollection(params);
        console.log(res);
        if (res.code) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.collectStatus = false;
        } else {
          this.$message({
            message: res.msg,
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 提交评论
    async submit() {
      if (!this.content) {
        this.$message({
          message: "请输入评论内容！",
          type: "error"
        });
        return;
      }
      try {
        let params = {
          content: this.content,
          aid: this.id,
          pid: this.pid //回复的用户上一条ID
        };
        let res = await this.$api.https.goCommentPost(params);
        console.log(res);
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.content = "";
          if (res.data && res.data.comment) {
            this.commentList = [res.data.comment, ...this.commentList];
          }
        } else {
          this.$message({
            message: res.msg,
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 弹窗开关
    showModelFn(flag) {
      if (flag == 1) {
        this.showModel = false;
        return;
      }
      this.showModel = true;
    },
    // 展示电话号码
    showPhone() {
      console.log(this.$store.state.vuex_config);
      let phone = this.$store.state.vuex_config.config.phone_number;
      this.$confirm("Please contact customer service", phone, {
        cancelButtonText: "Close",
        showConfirmButton: false,
        showClose: false,
        iconClass: "el-icon-phone",
        cancelButtonClass: "closeConfirm",
        center: true
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$vuex_color: #9b0000;
@mixin btnStyle($width, $size) {
  .el-button--primary.is-plain {
    color: $vuex_color;
    background: #ffffff;
    border-color: $vuex_color;
    width: $width;
    font-size: $size;
    transition: 0.3s;
  }
  .el-button--primary.is-plain:focus,
  .el-button--primary.is-plain:hover {
    color: #ffffff;
    background: $vuex_color;
    border-color: $vuex_color;
  }
}
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  .searchBox {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2d2d2;
    .input-search {
      width: 487px;
      height: 30px;
      border-radius: 5px;
    }
    .button-search {
      background-color: #fff;
    }
  }
  .menuBox {
    .elmenu {
      display: flex;
      justify-content: space-between;
      ::v-deep .el-menu-item {
        flex: 1;
        text-align: center;
      }
    }
    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      font-weight: bold;
    }
  }
  .breadcrumb {
    padding: 10px 50px 0;
    display: flex;
    align-items: center;
  }
  .detailBox {
    display: flex;
    flex-direction: column;
    padding: 0 50px 50px;
    font-size: 14px;
    .detailShow {
      .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin: 30px 0;
      }
      .content {
        line-height: 28px;
        ::v-deep img {
          width: 100%;
          margin: 20px 0;
        }
      }
    }
    .foldBox {
      max-height: 60vh;
      overflow: hidden;
    }
    .unfoldBox {
    }
    .otherBox {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #838383;
      .data {
      }
      .other {
        display: flex;
        div {
          margin-left: 30px;
          display: flex;
          align-items: center;
          i {
            margin-right: 10px;
            font-size: 20px;
          }
        }
        .collection {
          cursor: pointer;
        }
        .chooseColor {
          color: $vuex_color;
        }
      }
    }
    .unfold {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px 0 0;
      cursor: pointer;
      span {
        text-align: center;
      }
      span:nth-child(1) {
        font-size: 20px;
        i {
          transform: rotate(90deg);
        }
      }
      span:nth-child(2) {
        font-size: 16px;
        color: #2498e9;
      }
    }
    .comment {
      display: flex;
      flex-direction: column;
      .textareaBox {
        padding: 10px 0 0 20px;
        display: flex;
        background-color: #e4e8ef;
        border-radius: 5px;
        i {
          padding: 10px 0 0;
          font-size: 20px;
        }
        ::v-deep.el-textarea__inner {
          border: none;
          padding: 10px 20px 20px 10px;
          background-color: transparent;
        }
        ::placeholder {
          color: #282828;
        }
      }
      .btn {
        padding: 10px 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btnL,
        .btnR {
          display: flex;
          align-items: center;
          div {
            font-size: 12px;
            margin-right: 20px;
            color: #838383;
          }
          @include btnStyle(120px, 12px);
        }
        .btnL {
          flex: 1;
        }
      }
    }
    .commentBox {
      display: flex;
      flex-direction: column;
      .title {
        color: #838383;
        font-size: 12px;
        padding: 10px 0;
      }
      .commentList {
        display: flex;
        flex-direction: column;
        .commentItem {
          display: flex;
          margin-bottom: 10px;
          .avatar {
            width: 40px;
            height: 40px;
            margin: 0 10px 10px 10px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
          .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            .nameDate {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .name {
                color: #a1a1a1;
              }
            }
            .comment {
              display: block;
            }
          }
        }
      }
    }
  }
}
.applyModel {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .applyBox {
    width: 300px;
    box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 36%);
    .close {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 0 0 10px;
      i {
        font-size: 20px;
        margin: -10px -10px 0 0;
      }
    }
    .content {
      font-size: 14px;
    }
    .btnBox {
      padding: 20px 0 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @include btnStyle(100px, 16px);
    }
  }
}
</style>
